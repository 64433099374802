<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header py-4">
					<div class="row align-items-end">
						<div class="col d-flex flex-wrap">
							<div class="col-12 col-md-4 col-lg-3">
								<div class="form-group mb-0">
									<label class="form-label">Start Date</label>
									<div style="width: 100%">
										<v-datepicker v-model="params.start_date" style="width: 100%"></v-datepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4 col-lg-3">
								<div class="form-group mb-0">
									<label class="form-label">End Date</label>
									<div style="width: 100%">
										<v-datepicker v-model="params.end_date" style="width: 100%"></v-datepicker>
									</div>
								</div>
							</div>
						</div>
						<button class="btn btn-primary" @click="generateReport" :disabled="processingReport">
							{{ processingReport ? 'Processing...' : 'Generate Report' }}
						</button>

					</div>
				</div>
				<div class="card-body">
					<b-table striped hover selectable responsive :items="reports" :fields="reportFields" :current-page="currentPage"
						:busy="loading">
						<template #table-busy>
							<div class="d-flex flex-column justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
								<p class="text-center mt-2"><strong>Loading...</strong></p>
							</div>
						</template>

						<template #empty>
							<p class="text-center text-secondary">No records available</p>
						</template>

						<template #cell(url)="data">
							<a :href="data.value" class="text-success" target="_blank" rel="noopener noreferrer">
								Download
							</a>
						</template>
					</b-table>

				</div>
				<div class="card-footer" v-if="totalRecords">
					<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="itemsPerPage"></b-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
import { axiosInstance } from '@/plugins/axios'
import moment from 'moment'
import { computed, inject, ref, defineProps, watch, reactive } from 'vue'
import VueToastr from 'vue-toastr'

const props = defineProps({
  companyId: {
    type: [Number, String],
    default: null,
    required: true
  }
})

// const toastr = inject('$toastr')
const itemsPerPage = ref(20)
const currentPage = ref(1)
const totalRecords = ref(null)
const loading = ref(false)
const errorLoading = ref(false)
const reports = ref([])
const reportFields = ref([
  { key: 'name', label: 'Name' },
  // { key: "date", label: "Date" },
  { key: 'url', label: 'Download Link' }
])
const params = reactive({
  end_date: new Date(),
  start_date: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`)
})
const processingReport = ref(false)
const errorGeneratingReport = ref(false)

watch(currentPage, (value, oldValue) => {
    if (value && value !== oldValue) {
      fetchReports(false)
    }
})

const user = computed(() => {
  return this.$store.getters.currentUser
})

const fetchReports = (showLoader = true) => {
    if (showLoader) { loading.value = true }

    errorLoading.value = false
    axiosInstance
      .get(`/v1/corporates/${props.companyId}/trip-reports?metadata=true&limit=${itemsPerPage.value}&page=${currentPage.value}`)
      .then((res) => {
        reports.value = res.data.data
        totalRecords.value = res.data.metadata?.total
      })
      .catch(() => {
        errorLoading.value = true
      })
      .finally(() => {
        loading.value = false
      })
  }

  const forceDownload = (url) => {
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('target', '_blank')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

const generateReport = () => {
    processingReport.value = true

    errorGeneratingReport.value = false
    const startDate = moment(params.start_date)
    const endDate = moment(params.end_date)

    if (Object.prototype.hasOwnProperty.call(params, 'start_date') && Object.prototype.hasOwnProperty.call(params, 'end_date')) {
      if (startDate.isAfter(endDate)) {
        VueToastr.e(
          'Invalid date range error'
        )
        processingReport.value = false
        return false
      }
    }

    axiosInstance
      .post(`/v1/corporates/${props.companyId}/trip-reports`, {
        start_date: moment(params.start_date).format('YYYY-MM-DD'),
        end_date: moment(params.end_date).format('YYYY-MM-DD')
      })
      .then((res) => {
        forceDownload(res.data.url)

        fetchReports()
      })
      .catch(() => {
        errorGeneratingReport.value = true
      })
      .finally(() => {
        processingReport.value = false
      })
  }

fetchReports()
</script>
